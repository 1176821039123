<template>
  <div class="cpt-application_header">
    <div class="left">
      <div class="title" @click="handleClick">
        <img alt="" src="~@/assets/img/observe-shi-logo.png" />
        <span>联视</span>
      </div>
      <template>
        <div class="menu">
          <ul class="ul">
            <li class="tap" v-for="item in optionsLeft" :key="item.id" @click="tap_click(item)">
              <span>{{ item.title }}</span>
            </li>
          </ul>
        </div>
      </template>
    </div>
    <div class="right">
      <tianqi class="tianqi"></tianqi>
      <div class="mode_nameBox cp" v-if="flightMode">
        <!-- -->
        <el-dropdown trigger="click" @command="handle_change_uav_mode">
          <span class="dib">
            <span class="mode_name">{{ flightMode }}</span>
            <i class="el-icon-caret-bottom"></i>
          </span>
          <div class="el-popperBox">
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in modeList" v-show="!item.disabled" :key="item.id" :command="item.data">{{
                item.label }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </div>
      <HeaderRight :data="user" />
    </div>
  </div>
</template>

<script>
import HeaderRight from "./header-right/index";
// 引用专门的天气预报
// import tianqi from "./tianqi/index.vue";
import tianqi from "./tianqi";

// 引用tap背景
import TapLan from "@/assets/newImage/taplan.png";
import TapJin from "@/assets/newImage/tapJin.png";
export default {
  props: {
    title: {
      type: String,
      default: () => "鹰视",
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    uavData: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      optionsLeft: [
        { id: 1, title: "无人机应用", url: "#/fckernel" },
        { id: 2, title: "机巢应用", url: "#/fckernel?page=2" },
        { id: 3, title: "视频墙", url: "#/fckernelVideos" },
      ],
      modeList: [
        { id: 1, data: "11", label: "安全降落", disabled: false },
        { id: 2, data: "12", label: "返航模式", disabled: false },
        { id: 3, data: "13", label: "航线模式", disabled: false },
        { id: 4, data: "17", label: "gps模式", disabled: false },
      ],
      TapLan: TapLan,
      TapJin: TapJin,
      flightMode: "",
      tap: [
        { id: "1", name: "首页", path: "/observe/home?page=1" },
        { id: "2", name: "飞行应用", path: "/observe?page=3" },
        // { id: "3", name: "AI应用", path: "/aiUse" },
      ],
      jinId: 99,
    };
  },
  watch: {
    uavData: function (val, old) {
      if (val) {
        this.flightMode = val.flightMode;
      }
    },
    deep: true,
  },
  components: { HeaderRight, tianqi },
  methods: {
    tap_click (item) {
      window.open(item.url, "_blank");
    },
    handle_change_uav_mode (item) {
      console.log(item, "item");
      this.$emit("control_ws", item);
    },
    btn (path) {
      // console.log(1);
      // this.dianji = index;
      window.open("#" + path, "_self");
    },
    handleClick () {
      // window.open('#/home', "_blank");
      // this.$router.push('/home')

      let { href } = this.$router.resolve({ path: "/home" });
      window.open(href, "_self");
      setTimeout(() => {
        this.$router.go(0);
      }, 100);
    },
  },
  mounted () {
    // this.btn("/observe/home?page=1",0)
    // console.log(this.$route.path,'>>>')
    if (this.$route.path == "/observe/home") {
      this.jinId = 0;
    } else if (this.$route.path == "/observe") {
      this.jinId = 1;
    } else if (this.$route.path == "/aiUse") {
      this.jinId = 2;
    }
  },
};
</script>

<style lang="scss" scoped>
.cpt-application_header {
  width: 100%;
  height: 68px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: url("~@/assets/img/head_bg.png") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin-left: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      margin-top: -10px;

      img {
        width: 63px;
        height: 58px;
        margin-right: 43px;
      }
    }

    .menu {
      margin-left: 150px;

      ul {
        list-style: none;
        display: flex;
        height: 100%;
        vertical-align: top;

        .tap {
          width: 185px;
          height: 64px;
          position: relative;
          text-align: center;
          // margin: -8px 0 0 0;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
  }

  .right {
    display: flex;
    flex-direction: row;
  }
}

// -----------------------------------------------------
.tianqi {
  // padding: 7px 0 0 0;
  margin: 0 0 0 60px;
}

.tap {
  background-image: url("~@/assets/img/head-menu-bg-nomal.png");
  background-size: 100% 100%;
  cursor: pointer;
}

.tap:hover {
  background-image: url("~@/assets/img/head-menu-bg-select.png");
  background-size: 100% 100%;
}

.jin {
  background-image: url("~@/assets/img/head-menu-bg-select.png");
  background-size: 100% 100%;
}

.mode_nameBox {
  width: 116px;
  height: 30px;
  margin-top: 22px;
  background: url("~@/assets/images/observe/fckernel/nest/select.png") no-repeat !important;
  background-size: contain;
  margin-right: 5px;
  padding-left: 15px;
  display: flex;
  align-items: center;

  .mode_name {
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    color: #3df1ff;
    margin: 0 6px 0 0;
  }
}

.el-dropdown-menu {
  background: rgba(9, 32, 87, 0.5) !important;

  .el-dropdown-menu__item {
    background: rgba(9, 32, 87, 0.5);
    color: #fff !important;
  }

  .el-dropdown-menu__item:hover {
    color: #43deff !important;
    background-image: linear-gradient(90deg,
        rgba(25, 98, 136, 0.34) 0%,
        rgba(44, 135, 176, 0.7) 0%,
        rgba(26, 100, 139, 0.37) 74%,
        rgba(7, 61, 98, 0) 100%) !important;
  }
}

.btn {
  background-image: url("~@/assets/new_yingshi/btn_lan.png");
  background-size: 100% 100%;
  width: 216px;
  height: 46px;
  margin: 10px 10px 0 0;
  display: flex;
  justify-content: center;
  // align-items: center;
  padding: 10px 0 0 0;
  box-sizing: border-box;
  overflow: hidden;
}

.btn:hover {
  background-image: url("~@/assets/new_yingshi/btn_jin.png");
  cursor: pointer;
}

.btn_text {
  // width: 111px;
  height: 19px;
}

.version-btn {
  background-image: url("~@/assets/images/observe/version-btn.png");
  background-size: 100% 100%;
  height: 24px;
  color: #ffc15b;
  font-size: 12px;
  width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}</style>
