<template>
  <div class="home-header-right_box">
    <div class="right-list_box">
      <el-tooltip class="cp" content="消息通知" placement="bottom">
        <div class="list-item_box chat" @click="openmessagebox">
          <div class="Popout">
            <img src="~@/assets/zong_lan_ye_img/icon_消息通知.png" alt />
            <span>消息通知</span>
          </div>
          <div class="text">
            <!-- <span class="yuandian" v-if="number > 0"></span> -->
          </div>
        </div>
      </el-tooltip>
      <Message
        @handleClose="showmessage = false"
        @numbeichange="numbergb"
        v-show="showmessage"
      ></Message>
      <div class="list-item_box chat" @click="handle_logout">
        <div class="Popout">
          <img src="~@/assets/new_yingshi/usr.png" alt="" />
          <span>{{ user_info.name }}</span>
        </div>
        <div class="text">
          <!-- <span class="yuandian" v-if="number > 0"></span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Message from "@/pages/home/Confirmation_Dialogs/inform";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {},
  components: {
    Message,
  },
  inject: [],

  data() {
    return {
      number: null,
      // 消息提示框
      showmessage: false,
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  async mounted() {},
  methods: {
    ...mapActions("user", ["Logout"]),
    //打开消息提示框
    openmessagebox() {
      this.showmessage = !this.showmessage;
    },
    handle_logout() {
      this.$el_confirm("确定要退出登录吗？", () => {
        this.Logout();
        sessionStorage.clear();
        localStorage.clear();
      });
    },
    numbergb(num) {
      this.number = num;
    },
  },
};
</script>

<style lang="scss" scoped>
.home-header-right_box::v-deep {
  .right-list_box {
    height: 100%;
    display: flex;
    text-align: center;
    color: #fff;
    align-items: center;
    .list-item_box.chat {
      cursor: pointer;
      // &:hover {
      //   opacity: 0.7;
      // }
    }
    .list-item_box {
      display: flex;
      flex-direction: column;
      margin: 0px 10px 0 0;
      .Popout {
        width: 80px;
        height: 58px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 22px;
          height: 24px;
          margin-bottom: 5px;
        }
      }
      &::after {
        content: " ";
        display: block;
        width: 1px;
        height: 37px;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(50%);
        background-image: linear-gradient(
          206deg,
          rgba(36, 146, 252, 0) 5%,
          #2492fc 56%,
          rgba(36, 146, 252, 0) 100%
        );
      }
      &:last-child::after {
        display: none;
      }
      .icon {
        .iconfont {
          font-size: 36px;
          color: #00e4ff;
        }
        img {
          width: 41px;
          height: 46px;
          margin-bottom: 3px;
        }
      }
      .text {
        font-family: Microsoft YaHei;
        font-size: 8px;
        letter-spacing: 0;
        text-align: center;
        line-height: 10px;
        font-weight: 400;
        color: #00e4ff;
        position: relative;
        .yuandian {
          width: 5px;
          height: 5px;
          padding: 2px;
          background-color: red;
          border-radius: 50%;
          font-family: NotoSansCJKsc-Regular;
          font-size: 12px;
          color: #ffffff;
          text-align: center;
          font-weight: 400;
          position: absolute;
          top: -41px;
          right: 10px;
          text-align: center;
          line-height: 16px;
        }
      }
    }
  }

  .el-dropdown-menu {
    padding: 0;
    border: none;
    .message-box {
      width: 400px;
      height: 453px;
      background-color: #161d2d;
      box-sizing: border-box;
      border: 1px solid #00ffff;
      border-radius: 4px;
      padding-bottom: 10px;
      .tabs {
        display: flex;
        justify-content: space-between;
        i {
          margin: auto;
          color: #2edfff;
        }
        .tabBox {
          width: 300px;
          height: 47px;
          display: flex;
          flex-wrap: nowrap;
          overflow: hidden;
          .tab-box {
            width: 100px;
            height: 47px;
            flex-shrink: 0;
            // flex: 1;
            text-align: center;
            line-height: 47px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            cursor: pointer;
            border-radius: 4px 0px 0px 0px;
            font-size: 16px;
            color: #92d9ff;
            font-weight: 700;
            position: relative;
            &:hover {
              color: #08c2d1;
            }
            .tgml {
              position: absolute;
              top: 0px;
              right: 5px;
              font-size: 12px;
              display: inline-block;
              padding: 0.15em 0.4em;
              min-width: 8px;
              border-radius: 18px;
              background-color: #fa5151;
              color: #fff;
              line-height: 1.2;
              font-weight: 400;
            }
          }
        }
        .tab-box.tabMore {
          font-family: MicrosoftYaHeiUI;
          font-size: 14px;
          color: #08c2d1;
          font-weight: 400;
          margin: auto;
          cursor: pointer;
          position: relative;
          .btnMorecss {
            position: absolute;
            top: -20px;
            right: -10px;
            font-size: 12px;
            display: inline-block;
            padding: 0.15em 0.4em;
            min-width: 8px;
            border-radius: 18px;
            background-color: #fa5151;
            color: #fff;
            line-height: 1.2;
            font-weight: 400;
            margin-top: 5px;
          }
        }
        .tab-box.active {
          display: inline-block;
          background: linear-gradient(#08c2d1, #08c2d1) no-repeat;
          background-size: 65% 3px;
          background-position: 18px 2.3em;
          font-weight: bold;
          color: #00ffff;
        }
      }
      .table {
        height: calc(100% - 47px);
        padding: 0 10px;
        position: relative;
      }
      .tb-hd {
        display: flex;
        .td {
          flex: 1;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #43c7f0;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
          text-align: center;
          line-height: 30px;
        }
      }
      .tb-bd {
        width: 100%;
        overflow: auto;
        height: calc(100% - 30px);
        .tr {
          display: flex;
          min-height: 59px;
          align-items: center;
          &:nth-child(odd) {
            background: #2b3857;
          }
          &:nth-child(even) {
            background: #1e2a45;
          }
          .zhtd {
            width: 126px;
          }
          .td {
            flex: 1;
            text-align: center;
            font-family: MicrosoftYaHei;
            font-size: 12px;
            color: #9bffff;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
            word-break: break-all;
            &.ope {
              color: #6aff64;
              cursor: pointer;
              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }

  .el-dialog {
    background: transparent;
    background-image: url("~@/assets/images/approve.png");
    background-size: 100% 100% !important;
    background-repeat: no-repeat;
  }
  .el-dialog__title {
    color: #fff;
    font-weight: 700;
    text-align: left;
  }
  .el-dialog__header {
    text-align: left;
    margin-left: 100px;
  }
  .el-textarea__inner {
    color: #ffff;
    background-color: #000000;
    border: 1px solid #08c2d1;
  }
  .el-form-item__label {
    color: #fff;
  }
}
</style>
